<template>
    <div class="exDiv">
        
        <el-carousel  height="380px"  style="position:relative">
            <el-carousel-item>
                <div class="exContent" style="">
                    <div class="aqImgDiv" v-for="(item,i) in listData" :key="i">
                        <img :src="item"/>
                    </div>
                </div>
            </el-carousel-item>
            <el-carousel-item>
                <div class="exContent" style="">
                    <div class="aqImgDiv" v-for="(item,i) in exampleData" :key="i">
                        <img :src="item"/>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
        <!-- <el-row class="anRowFooter">
          <el-col :span=24>
              <el-pagination background  
              layout="prev, pager, next"  
              :total="count" 
              :page-size="3"
              @current-change=currentPageClick

              >
              </el-pagination>
          </el-col>
      </el-row> -->
    </div>
</template>
<script>
export default {
    name:"exampleMember",
    data(){
        return{
            listData:[
                 require("../../static/example/banner1_1.png"),
                require("../../static/example/banner1_2.png"),
                require("../../static/example/banner1_3.png"),
                require("../../static/example/banner1_4.png"),
                require("../../static/example/banner1_5.png"),
                require("../../static/example/banner1_6.png"),
                require("../../static/example/banner1_7.png"),
                require("../../static/example/banner1_8.png"),
                require("../../static/example/banner1_9.png"),
                require("../../static/example/banner1_10.png"),
                require("../../static/example/banner1_11.png"),
                require("../../static/example/banner1_12.png"),
            ],
            count:21,
            currentPage:1,
            newList:[],
            exampleData:[
                require("../../static/home1/logo1.png"),
                require("../../static/home1/logo2.png"),
                require("../../static/home1/logo3.png"),
                require("../../static/home1/logo4.png"),
                require("../../static/home1/logo5.png"),
                require("../../static/home1/logo6.png"),
                require("../../static/home1/logo7.png"),
                require("../../static/home1/logo8.png"),
                require("../../static/home1/logo9.png"),
                require("../../static/home1/logo10.png"),
                require("../../static/home1/logo11.png"),
                require("../../static/home1/logo12.png"),
            ],
        }
        
    },
    methods:{
        init(){
            
            // this.count=list.length
            // this.currentPageClick(1)
        
        },
        currentPageClick(page){
            this.newList=[]
            // this.currentPage=page
            let start=(page-1)*3
            let list=[]
            for(let i=0;i<3;i++){
                if(start+i+1<=this.listData.length){
                    let item=this.listData[start+i]
                    list.push(item)
                }
                
            }
            this.newList=list
            this.$forceUpdate()
        },
       moreClick(row){
           this.$router.push({
               name:"aboutNewsInfo",
               query:{
                    name:row.title,
                    type:"about",
               }
           })
       }
    },
    created(){
        this.init()
    }
}
</script>
<style>
.exDiv{
    /* padding-top:60px; */
    padding-bottom:60px;
}
.aqImgDiv{
    width: 23%;
}
.exDiv .exContent{
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between;
    margin-top:20px;
}
.exDiv .exContent img{
    width:100%;
    margin-top:20px;
}
.exDiv .el-carousel__indicator .el-carousel__button{
    background: #409EFF;
}
</style>
